/* eslint-disable import/prefer-default-export */
import jsonp from 'jsonp';
import toQueryString from 'to-querystring';

export const subscribeToMailchimp = (values) => {
  const params = toQueryString(values);
  const url = `${'https://app.us20.list-manage.com/subscribe/post-json?u=c4d8872f669c5e3757178de4c&id=0f64cdd43c&'}${params}`;
  return new Promise((resolve, reject) => jsonp(url, { param: 'c' },
    (err, data) => {
      if (err) {
        return reject(err);
      } if (data.result !== 'success') {
        return reject(new Error(JSON.stringify(data.msg)));
      }
      return resolve(data);
    }));
};
