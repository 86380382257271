import {
  all,
  takeEvery,
  takeLatest,
  put,
  call,
  select,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { startSubmit, stopSubmit } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import auth from '../services/auth';
import firebase, { googleAuthProvider } from '../config';
import actions from '../shared/actions/auth';
import { GET_USER_PROFILE_QUERY, GET_USER_LATEST_POSTS } from '../graphql';

export function* UPDATE_USER_GROUP_ADMIN() {
  const profile = yield select(state => state.auth.profile);
  yield put({
    type: 'user/SET_STATE',
    payload: {
      profile: {
        ...profile,
        groupsAdmin: true,
      },
    },
  });
}

export function* GOOGLE_AUTH_LOGIN() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      success: false,
      authorized: false,
    },
  });
  try {
    const userCredential = yield call(auth.signInWithPopup, googleAuthProvider);
    if (userCredential) {
      const userToken = yield call(auth.getIdToken, true);
      localStorage.setItem('token', userToken);
      const userProfile = yield call(auth.getProfile);

      yield put({
        type: 'notification/SHOW_NOTIFICATION',
        payload: {
          message: 'Something went wrong please try again later.',
          variant: 'info',
        },
      });
      if (userProfile.success) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            authorized: true,
            loading: false,
            success: true,
            profile: userProfile.user,
          },
        });
        if (isEmpty(userProfile.user.fullname)) {
          yield put({
            type: 'user/UPDATE_USER_PROFILE',
            payload: {
              values: { fullname: userCredential.user.displayName },
            },
          });
        }
      } else {
        yield put({
          type: 'notification/SHOW_NOTIFICATION',
          payload: {
            message: 'Something went wrong please try again later.',
            variant: 'error',
          },
        });
      }
    }
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: 'You have successfully loggedIn',
        variant: 'success',
      },
    });
  } catch (error) {
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: error.message,
        variant: 'error',
      },
    });
  }
}

export function* PHONE_SEND_LOGIN({ payload }) {
  try {
    window.applicationVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      { size: 'invisible' }
    );
    yield put(startSubmit('loginForm'));
    yield put({
      type: 'user/SEND_OTP_CODE',
      payload,
    });
  } catch (error) {
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: error.message,
        variant: 'error',
      },
    });
    yield put({
      type: 'user/SET_STATE',
      payload: {
        error: true,
      },
    });
  }
}

export function* SEND_OTP_CODE({ payload: { phoneNumber } }) {
  try {
    const confirmationResult = yield call(
      auth.signInWithPhoneNumber,
      phoneNumber,
      window.applicationVerifier
    );
    window.confirmationResult = confirmationResult;
    yield put(stopSubmit('loginForm'));
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: 'Verification code has been sent successfully.',
        variant: 'success',
      },
    });
    yield put({
      type: 'user/SET_STATE',
      payload: {
        messageSent: true,
      },
    });
  } catch (error) {
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: error.message,
        variant: 'error',
      },
    });
    yield put({
      type: 'user/SET_STATE',
      payload: {
        error: true,
      },
    });
  }
}

export function* VERIFY_OTP_CODE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
      success: false,
      authorized: false,
    },
  });
  try {
    const credential = firebase.auth.PhoneAuthProvider.credential(
      window.confirmationResult.verificationId,
      payload
    );
    const userCredentials = yield call(auth.signInWithCredential, credential);
    if (userCredentials) {
      const userToken = yield call(auth.getIdToken, true);
      localStorage.setItem('token', userToken);
      const userProfile = yield call(auth.getProfile);
      if (userProfile.success) {
        if (userProfile.user.type
          !== 'A') {
          yield put(push('/unaccessible'));
          yield put({
            type: 'user/SET_STATE',
            payload: {
              authorized: true,
              loading: false,
              success: true,
              profile: userProfile.user,
            },
          });
        } else {
          yield put(push('/'));
          yield put({
            type: 'user/SET_STATE',
            payload: {
              authorized: true,
              loading: false,
              success: true,
              profile: userProfile.user,
            },
          });
        }
      } else {
        yield put({
          type: 'notification/SHOW_NOTIFICATION',
          payload: {
            message: 'Something went wrong please try again later.',
            variant: 'error',
          },
        });
      }
    }
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: 'You have successfully loggedIn',
        variant: 'success',
      },
    });
  } catch (error) {
    let staticMessage = '';
    if (error.code === 'auth/invalid-verification-code') {
      staticMessage = 'Phone authentication failed. Please click on Resend';
    } else {
      staticMessage = error.message;
    }
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: staticMessage,
        variant: 'error',
      },
    });
    yield put({
      type: 'user/SET_STATE',
      payload: {
        loading: false,
      },
    });
  }
}

export function* CLEAR_RECAPTCHA() {
  if (window.applicationVerifier) {
    window.applicationVerifier.render().then((widgetId) => {
      window.grecaptcha.reset(widgetId);
    });
    window.applicationVerifier.clear();
    if (window.recaptchaWrapperRef) {
      window.recaptchaWrapperRef.innerHTML = '<div id="recaptcha-container"></div>';
    }
  }
  yield put(stopSubmit('loginForm'));
}

export function* REFRESH_TOKEN(action) {
  try {
    const currentAccount = yield call(auth.currentAccount);
    localStorage.setItem('currAcc', currentAccount.uid);
    console.log('currentAccount', currentAccount.uid);
    if (currentAccount) {
      const userToken = yield call(auth.getIdToken, true);
      localStorage.setItem('token', userToken);
      action.resolve(userToken);
    } else {
      action.resolve();
    }
  } catch (error) {
    localStorage.removeItem('token');
    action.reject(error);
  }
}

export function* UPDATE_PROFILE({ payload }) {
  yield put(startSubmit('updateProfileForm'));
  const updateProfile = yield call(auth.updateProfile, payload);
  if (updateProfile.success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        profile: updateProfile.data,
      },
    });
  } else {
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: updateProfile.message,
        variant: 'error',
      },
    });
  }
  yield put(stopSubmit('updateProfileForm'));
}

export function* ANONYMOUSLY_LOGIN() {
  try {
    const anonymously = yield call(auth.signInAnonymously);
    if (anonymously) {
      const userToken = yield call(auth.getIdToken, true);
      localStorage.setItem('token', userToken);
      yield put({
        type: 'user/SET_STATE',
        payload: {
          authorized: false,
          isAnonymous: true,
          profile: {},
        },
      });
    }
  } catch (error) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        authorized: false,
        isAnonymous: false,
        profile: {},
      },
    });
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: error.message,
        variant: 'error',
      },
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authLoading: true,
    },
  });
  const currentAccount = yield call(auth.currentAccount);
  if (currentAccount) {
    const userToken = yield call(auth.getIdToken, true);
    localStorage.setItem('token', userToken);
    if (!currentAccount.isAnonymous) {
      const response = yield call(auth.getProfile);
      if (response.success) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            authorized: true,
            authLoading: false,
            profile: response.user,
          },
        });
      } else {
        yield put({
          type: 'notification/SHOW_NOTIFICATION',
          payload: {
            message: 'Something went wrong please try again later.',
            variant: 'error',
          },
        });
        yield put({
          type: 'user/SET_STATE',
          payload: {
            authLoading: false,
          },
        });
      }
    }
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authLoading: false,
    },
  });
}

export function* LOGOUT_ACTIVE_ACCOUNT({ payload: { client } }) {
  yield call(auth.signOut);
  // client.clearStore();
  localStorage.clear();
  yield put(push('/'));
  yield put({
    type: 'user/SET_STATE',
    payload: {
      authorized: false,
      profile: {},
    },
  });
}

export function* GET_USER_PROFILE({ payload }) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      user: payload,
    },
  });
}

export function* UPDATE_USER_AVATAR({ payload }) {
  const { file, client } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      profileLoading: true,
    },
  });
  const response = yield call(auth.updateAvatar, file);
  if (response.success) {
    const profile = yield call(auth.getProfile);
    if (profile.success) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          profile: profile.user,
        },
      });
      yield put({
        type: 'notification/SHOW_NOTIFICATION',
        payload: {
          message: 'Profile photo has been changed.',
          variant: 'success',
        },
      });
      if (client) {
        const { getUser } = client.readQuery({
          query: GET_USER_PROFILE_QUERY,
          variables: { ID: profile.user.guid },
        });
        let { getUserLatestMessages } = client.readQuery({
          query: GET_USER_LATEST_POSTS,
          variables: {
            messageTypes: [8, 9, 11, 10, 20, 21],
            userID: profile.user.guid,
            offset: 0,
            limit: 10,
          },
        });
        getUserLatestMessages = getUserLatestMessages.map(message => ({
          ...message,
          creator: {
            ...message.creator,
            profilePic: profile.user.avatarURL,
          },
        }));
        client.writeQuery({
          query: GET_USER_LATEST_POSTS,
          variables: {
            messageTypes: [8, 9, 11, 10, 20, 21],
            userID: profile.user.guid,
            offset: 0,
            limit: 10,
          },
          data: {
            getUserLatestMessages,
          },
        });
        client.writeQuery({
          query: GET_USER_PROFILE_QUERY,
          variables: { ID: profile.user.guid },
          data: {
            getUser: {
              ...getUser,
              profilePic: profile.user.avatarURL,
            },
          },
        });
      }
    } else {
      yield put({
        type: 'notification/SHOW_NOTIFICATION',
        payload: {
          message:
            'There was an error, cropping your profile photo. Please try again.',
          variant: 'error',
        },
      });
    }
  } else {
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message:
          'There was an error, cropping your profile photo. Please try again.',
        variant: 'error',
      },
    });
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      profileLoading: false,
    },
  });
}

export function* UPDATE_USER_PROFILE({ payload }) {
  const { values, client } = payload;
  yield put(startSubmit('updateProfileForm'));
  const updateProfile = yield call(auth.updateProfile, values);
  if (updateProfile.success) {
    yield put({
      type: 'user/SET_STATE',
      payload: {
        profile: updateProfile.data,
      },
    });
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: 'Profile updated',
        variant: 'success',
      },
    });
    if (client) {
      const { getUser } = client.readQuery({
        query: GET_USER_PROFILE_QUERY,
        variables: { ID: updateProfile.data.guid },
      });
      let { getUserLatestMessages } = client.readQuery({
        query: GET_USER_LATEST_POSTS,
        variables: {
          messageTypes: [8, 9, 11, 10, 20, 21],
          userID: updateProfile.data.guid,
          offset: 0,
          limit: 10,
        },
      });
      getUserLatestMessages = getUserLatestMessages.map(message => ({
        ...message,
        creator: {
          ...message.creator,
          fullname: updateProfile.data.profileFields.fullname,
        },
      }));
      client.writeQuery({
        query: GET_USER_LATEST_POSTS,
        variables: {
          messageTypes: [8, 9, 11, 10, 20, 21],
          userID: updateProfile.data.guid,
          offset: 0,
          limit: 10,
        },
        data: {
          getUserLatestMessages,
        },
      });
      client.writeQuery({
        query: GET_USER_PROFILE_QUERY,
        variables: { ID: updateProfile.data.guid },
        data: {
          getUser: {
            ...getUser,
            fullname: updateProfile.data.profileFields.fullname,
            bio: updateProfile.data.profileFields.bio,
            gender: updateProfile.data.profileFields.gender,
          },
        },
      });
    }
  } else {
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: updateProfile.message,
        variant: 'error',
      },
    });
  }
  yield put(stopSubmit('updateProfileForm'));
}
export function* UPDATE_USER_SETTING({ payload }) {
  const response = yield call(auth.updateProfile, payload);
  if (response.success) {
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: 'Setting Updated',
        variant: 'success',
      },
    });
    yield put({
      type: 'user/SET_STATE',
      payload: {
        profile: response.data.data,
      },
    });
  } else {
    yield put({
      type: 'notification/SHOW_NOTIFICATION',
      payload: {
        message: 'Something went wrong please try again later.',
        variant: 'error',
      },
    });
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(actions.UPDATE_USER_GROUP_ADMIN, UPDATE_USER_GROUP_ADMIN),
    takeEvery(actions.GOOGLE_AUTH_LOGIN, GOOGLE_AUTH_LOGIN),
    takeEvery(actions.PHONE_SEND_LOGIN, PHONE_SEND_LOGIN),
    takeEvery(actions.SEND_OTP_CODE, SEND_OTP_CODE),
    takeEvery(actions.VERIFY, VERIFY_OTP_CODE),
    takeEvery(actions.CLEAR_RECAPTCHA, CLEAR_RECAPTCHA),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeLatest(actions.REFRESH_TOKEN, REFRESH_TOKEN),
    takeLatest(actions.UPDATE_PROFILE, UPDATE_PROFILE),
    takeLatest(actions.ANONYMOUSLY_LOGIN, ANONYMOUSLY_LOGIN),
    takeLatest(actions.GET_USER_PROFILE, GET_USER_PROFILE),
    takeLatest(actions.UPDATE_USER_AVATAR, UPDATE_USER_AVATAR),
    takeLatest(actions.UPDATE_USER_PROFILE, UPDATE_USER_PROFILE),
    takeLatest(actions.UPDATE_USER_SETTING, UPDATE_USER_SETTING),
    takeLatest(actions.LOGOUT_ACTIVE_ACCOUNT, LOGOUT_ACTIVE_ACCOUNT),
    LOAD_CURRENT_ACCOUNT(),
  ]);
}
