import React, { useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import useStyle from './styles';

const Header = React.lazy(() => import('../../shared/header'));
// const BottomNavigation = React.lazy(() => import('../../shared/drawers/bottomNavigation'));
// const LeftNavigation = React.lazy(() => import('../../shared/drawers/leftNavigation'));
// const RightNavigation = React.lazy(() => import('../../shared/drawers/rightNavigation'));

const TwitterLayout = ({ children, location }) => {
  const classes = useStyle();
  const recaptchaWrapperRef = useRef();
  useLayoutEffect(() => {
    window.recaptchaWrapperRef = recaptchaWrapperRef.current;
  }, [recaptchaWrapperRef.current]);
  const pageclass = location.pathname.replace(/\//g, " ");
  return (
    <div className={classes.root}>
      <Header />
      <div className={classes.container}>
        {/* <LeftNavigation /> */}
        <main className={classes.contentContainer+pageclass}>
          <div className={classes.content}>
            <div className={classes.topToolbar} />
            <div className={classes.contentWrapper}>
              <div className={classes.childrenContainer}>
                {children}
              </div>
              {/* <RightNavigation /> */}
            </div>
            <div className={classes.bottomToolbar} />
          </div>
        </main>
      </div>
      <div ref={recaptchaWrapperRef}>
        <div id="recaptcha-container" />
      </div>
    </div>
  );
};

TwitterLayout.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

export default React.memo(withRouter(TwitterLayout));
