import gql from 'graphql-tag';

export const GET_USER_PROFILE_QUERY = gql`
  query getUser($ID: ID!) {
    getUser(ID: $ID) {
      ID
      username
      fullname
      profilePic
      bio
      userType
      gender
      online
      verified
      followStatus
      blocked
      insightsTotal
      messagesLikedTotal
      followersTotal
      followingTotal
      __typename
    }
  }
`;

export const GET_USER_LATEST_POSTS = gql`
  query getAllLatestMessages(
    $messageTypes: [ID!]
    $status: Int
    $onlyMy: Boolean
    $includeThreads: Boolean
    $limit: Int
    $offset: Int
  ) {
    getAllLatestMessages(
      messageTypes: $messageTypes
      status: $status
      onlyMy: $onlyMy
      includeThreads: $includeThreads
      limit: $limit
      offset: $offset
    ) {
      ID
      community {
        title
      }
      communityID
      messageType
      createdOn
      creator {
        ID
        username
        verified
        fullname
        profilePic
        followedByMe
        followStatus
      }
      status
      content
      statistics {
        isLikedSelf
        views
        likes
        shares
        delivers
        threadReplies
      }
      __typename
    }
  }
`;

export const GET_USER_LATEST_BLOG_POSTS = gql `
query getLatestBlogPosts(
  $categoryId:[ID!]
  $status: Int
  $onlyMy: Boolean
  $limit: Int
  $offset: Int
  $showLockedContent : Boolean) {
  getLatestBlogPosts(
    categoryId:$categoryId
    status: $status
    onlyMy: $onlyMy
    limit: $limit
    offset: $offset
    showLockedContent : $showLockedContent
  ) {
    ID
    category{
      title
    }
    categoryId
    createdOn
    creator {
      ID
      username
      verified
      fullname
      profilePic
      followedByMe
      followStatus
      __typename
    }
    status
    content
    __typename
  }
}
`;

export const GET_USER_LIKED_POSTS = gql`
  query getUserLikedMessages(
    $messageTypes: [ID!]
    $userID: ID!
    $limit: Int
    $offset: Int
  ) {
    getUserLikedMessages(
      userID: $userID
      messageTypes: $messageTypes
      limit: $limit
      offset: $offset
    ) {
      ID
      community {
        title
      }
      communityID
      messageType
      createdOn
      creator {
        ID
        verified
        username
        fullname
        profilePic
        followedByMe
        followStatus
      }
      status
      content
      statistics {
        isLikedSelf
        views
        likes
        shares
        delivers
        threadReplies
      }
      __typename
    }
  }
`;

export const SET_USER_BLOCKED = gql`
  mutation setUserBlocked($userID: ID!, $block: Boolean!) {
    setUserBlocked(userID: $userID, block: $block) {
      ID
      blocked
    }
  }
`;

export const GET_USER_GROUP_ADMINS = gql`
  query getAutoAdmins(
    $userID: ID!
    $type: Int
    $status: Int
  ) {
    getAutoAdmins(userID: $userID, type: $type, status: $status) {
      type
      status
    }
  }
`;


export const GET_APP_USERS = gql`
  query getAppUsers(
    $isInvestor: Boolean!
    $isSubscriber: Boolean!
    $searchText: String!
    $limit: Int
    $offset: Int
  ){
    getUsers(search: $searchText, isSubscriber: $isSubscriber, isInvestor: $isInvestor, limit: $limit, offset: $offset){
      nextPage
      users{
        fullname
        primaryEmail
        primaryPhone
        investedAmount
        subscriberType{
          months
        }
      }
    }
  }
`;
