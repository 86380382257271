import gql from 'graphql-tag';

export const GET_ORDER_VALUES = gql`
  {
    orderValue @client
  }
`;

export const UPDATE_ORDER_VALUE = gql`
  mutation updateOrderValue($orderValue: orderValue!) {
    updateOrderValue(orderValue: $orderValue) @client
  }
`;
