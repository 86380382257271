import {
  all, put, call, take, takeEvery
} from 'redux-saga/effects';
import auth from '../services/auth';

function* INITIALIZE_AUTH_CHANNEL() {
  const channel = yield call(auth.authChannel);
  while (true) {
    const { error, user } = yield take(channel);
    if (user) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          authorized: true,
          profile: user
        },
      });
    } else {
      if (error) {
        yield put({
          type: 'notification/SHOW_NOTIFICATION',
          payload: {
            message: error.message,
            variant: 'error'
          }
        });
      }
      yield put({
        type: 'user/SET_STATE',
        payload: {
          authorized: false,
        },
      });
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery('INITIALIZE_AUTH_CHANNEL', INITIALIZE_AUTH_CHANNEL)]);
}
