import { eventChannel } from 'redux-saga';
import { call } from 'redux-saga/effects';
import firebase from '../config';
import axios from '../config/axios';
import axioss from 'axios';
import { getMutatedData } from './getMutatedData';
import { GRAPHQL_URL } from '../config/index';

const auth = firebase.auth();
let _authChannel = null;

function authChannel() {
  if (_authChannel) return _authChannel;
  const channel = eventChannel((emit) => {
    const unsubscribe = auth.onAuthStateChanged(
      user => emit({ user }),
      error => emit({ error })
    );
    return unsubscribe;
  });

  _authChannel = channel;
  return channel;
}

function* signInWithPhoneNumber(phoneNumber, applicationVerifier) {
  return yield call(
    [auth, auth.signInWithPhoneNumber],
    phoneNumber,
    applicationVerifier
  );
}

function* signInWithPopup(authProvider) {
  return yield call([auth, auth.signInWithPopup], authProvider);
}

function* signInWithCredential(credential) {
  return yield call([auth, auth.signInWithCredential], credential);
}

function* signOut() {
  return yield call([auth, auth.signOut]);
}

function* onIdTokenChanged() {
  return yield call([auth, auth.onIdTokenChanged]);
}

function* signInAnonymously() {
  return yield call([auth, auth.signInAnonymously]);
}

function getIdToken(credential) {
  return auth.currentUser
    .getIdToken(credential)
    .then(success => success)
    .catch(error => error);
}

async function currentAccount() {
  let userLoaded = false;
  function getCurrentUser() {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(firebase.auth.currentUser);
      }
      const unsubscribe = auth.onAuthStateChanged((user) => {
        userLoaded = true;
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }
  return getCurrentUser();
}

 async function getProfile() {

  
//   query whoAmI {
//     whoAmI {
//       ID
//       fullname
//       primaryEmail
//       primaryPhone
//       profilePic
//       investedAmount
//       isSubscriber
//       isInvestor
//       nextPayment
//       settings{
//         name 
//         value
//       }
//     }
//   }
// `;

const graphqlQuery={
"operationName":null,
"variables":{},
query:
  `query { whoAmI 
    
    
    {
    ID
    username
    fullname
    profilePic
    bio
    userType
    gender
    ageRange
    expLevel
    verified
    identityVerified
    kraVerified
    ckycVerified
    signatureSet
    primaryEmail
    primaryPhone
    online
    followedByMe
    followsMe
    followStatus
    backFollowStatus
    blocked
    insightsTotal
    followersTotal
    followingTotal
    groupsTotal
    insightsLikedTotal
    messagesLikedTotal
    groupsAdmin
    isSubscriber
    refcode
    accessRights
    subscriberType {
      months
    }
    nextPayment
    settings {
      name
      value
      guid
      created_at
      updated_at
    }
    investedAmount
    isInvestor
    summary {
      lastPaidAmount
      totalPaidAmount
      lastPaidGST
      premiumStart
      premiumEnd
      phoneNumber
    }
  }


}`
}

 let token = localStorage.getItem('token');

let data = await axioss({
  url: GRAPHQL_URL,
  method: 'post',
  headers: {
    "authorization":`Bearer ${token}` 

  },
  data: JSON.stringify(graphqlQuery)
}).then(response => {
  
  const user = getMutatedData(response?.data?.data)
  return({
  success: true,
  user:user.data

})})
.catch(error => ({
  success: false,
  ...error.response?.data?.data
}))
return data;
}

function updateProfile(profile) {
  // return axios(4)
  //   .post('update-profilefields', JSON.stringify(profile))
  //   .then(response => ({
  //     success: true,
  //     data: response.data.data
  //   }))
  //   .catch(error => ({
  //     success: false,
  //     ...error.response.data.data
  //   }));
}

function updateAvatar(file) {
  return axios(4)
    .put('upload-image-file&type=avatar', file)
    .then(response => ({
      success: true,
      ...response.data.data
    }))
    .catch(error => ({
      success: false,
      ...error.response.data.data
    }));
}

function updateSetting(data) {
  return axios(4)
    .post('update-my-setting', JSON.stringify(data))
    .then(response => ({
      success: true,
      ...response.data.data
    }))
    .catch(error => ({
      success: false,
      ...error.response.data.data
    }));
}

export default {
  signInWithPhoneNumber,
  signInWithCredential,
  signInAnonymously,
  signInWithPopup,
  onIdTokenChanged,
  getIdToken,
  signOut,
  authChannel,
  currentAccount,
  getProfile,
  updateProfile,
  updateAvatar,
  updateSetting
};
