import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import notification from '../notification/reducer';
import screen from '../screens/reducer';

import reducers from '../../modules/reducers';

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  form: formReducer,
  auth: reducers.authReducer,
  message: reducers.messageReducer,
  screen,
  notification
});

export default rootReducer;
