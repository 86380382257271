import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  container: {
    minHeight: 500,
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100%',
      minHeight: 'auto',
    },
  },
  backContainer: {},
  closeButton: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(1),
    zIndex: 1,
    color: theme.palette.grey[500],
  },
  logoutButton: {
    left: 'auto',
    right: 15,
    color: '#000',
  },
  formContainer: {
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 15,
    position: 'relative',
  },
  button: {
    fontWeight: 700,
    padding: '5px 45px',
    fontSize: 18,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  avatarContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,
    marginTop: 20,
  },
  avatar: {
    width: '100px',
    height: '100px',
    margin: 'auto',
  },
  iconContainer: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 100,
    height: '100%',
  },
}));
