import gql from 'graphql-tag';

export const FOLLOW_USER = gql`
    mutation followUser($ID: ID!){
        followUser(ID: $ID){
            ID
            followedByMe
            followsMe
            followStatus
            __typename
        }
    }
`;

export const UNFOLLOW_USER = gql`
    mutation unfollowUser($ID: ID!){
        unfollowUser(ID: $ID){
            ID
            followedByMe
            followsMe
            followStatus
            __typename
        }
    }
`;
