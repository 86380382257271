import gql from "graphql-tag";

export const GET_COMMUNITIES_QUERY = gql`
  query getMyCommunities($searchText: String, $offset: Int, $limit: Int) {
    getMyCommunities(searchText: $searchText, offset: $offset, limit: $limit) {
      ID
      coverPhoto
      title
      description
      createdOn
      reach
      status
      __typename
    }
  }
`;

export const GET_CATEGORIES_QUERY = gql `
query getMyCategories($searchText: String, $offset: Int, $limit: Int, $disableLangCheck: Boolean) {
  getMyCategories(searchText: $searchText, offset: $offset, limit: $limit, disableLangCheck:$disableLangCheck) {
    ID
    coverPhoto
    title
    description
    createdOn
    reach
    __typename
  }
}
`

export const GET_SUGGESTED_COMMUNITIES_QUERY = gql`
  query listSuggestedCommunities(
    $searchText: String
    $offset: Int
    $limit: Int
  ) {
    listSuggestedCommunities(
      searchText: $searchText
      offset: $offset
      limit: $limit
    ) {
      ID
      coverPhoto
      title
      description
      createdOn
    }
  }
`;

export const GET_COMMUNITY_QUERY = gql`
  query getCommunity($ID: ID!) {
    getCommunity(ID: $ID) {
      ID
      title
      description
      coverPhoto
      createdOn
      creator {
        ID
        username
        fullname
        profilePic
      }
      reach
      status
      peer {
        user {
          ID
          username
          fullname
        }
      }
      membership {
        status
        online
        notificationLevel
      }
      statistics {
        members
      }
      lastMessages(includeThreads: true, limit: 30) {
        ID
        communityID
        content
        creator {
          ID
          username
          fullname
          gender
          profilePic
          followStatus
        }
        parentID
        parent {
          ID
          communityID
          messageType
          creator {
            ID
            username
            fullname
            gender
            profilePic
          }
          content
          status
        }
        statistics {
          isLikedSelf
          views
          likes
          shares
          delivers
          threadReplies
        }
        createdOn
        messageType
        lastUpdated
        status
        statusMessage
        __typename
      }
    }
  }
`;

export const SEARCH_COMMUNITIES = gql`
  query searchCommunities(
    $searchText: String!
    $joined: Boolean
    $includePrivate: Boolean
    $offset: Int
    $limit: Int
  ) {
    searchCommunities(
      searchText: $searchText
      joined: $joined
      includePrivate: $includePrivate
      offset: $offset
      limit: $limit
    ) {
      ID
      title
      description
      coverPhoto
      createdOn
      creator {
        ID
        username
        fullname
        gender
        profilePic
      }
      peer {
        user {
          ID
          username
          fullname
        }
      }
      reach
      status
      statistics {
        members
      }
      membership {
        status
        online
        notificationLevel
      }
      lastMessage {
        ID
        communityID
        content
        parentID
        parent {
          ID
          communityID
          messageType
          creator {
            ID
            username
            fullname
            gender
            profilePic
          }
          content
          status
        }
        creator {
          ID
          username
          fullname
          gender
          profilePic
          followStatus
        }
        statistics {
          isLikedSelf
          views
          likes
          shares
          delivers
          threadReplies
        }
        createdOn
        messageType
        lastUpdated
        status
        statusMessage
        __typename
      }
      __typename
    }
  }
`;

export const GET_COMMUNITY_MEMBERS = gql`
  query getCommunityMembers($ID: ID!, $offset: Int, $limit: Int) {
    getCommunityMembers(ID: $ID, offset: $offset, limit: $limit) {
      communityID
      userID
      user {
        username
        fullname
        profilePic
        userType
        gender
        verified
      }
      online
      status
    }
  }
`;

export const GET_COMMUNITY_VALUE = gql`
  {
    getCommunity @client {
      ID
      title
      description
      coverPhoto
      createdOn
      creator {
        ID
        username
        fullname
        profilePic
      }
      peer {
        user {
          ID
          username
          fullname
        }
      }
      reach
      status
      membership {
        status
        online
        notificationLevel
      }
      reach
      status
      statistics {
        members
      }
    }
  }
`;

export const GET_COMMUNITY_VALUES = gql`
  {
    communities @client {
      ID
      title
      description
      coverPhoto
      createdOn
      creator {
        ID
        username
        fullname
        profilePic
      }
      peer {
        user {
          ID
          username
          fullname
        }
      }
      reach
      status
      membership {
        status
        notificationLevel
      }
      reach
      status
      statistics {
        members
      }
      lastMessages {
        ID
        communityID
        content
        parentID
        parent {
          ID
          communityID
          messageType
          creator {
            ID
            username
            fullname
            gender
            profilePic
          }
          content
          status
        }
        creator {
          ID
          username
          fullname
          gender
          profilePic
          followStatus
        }
        statistics {
          isLikedSelf
          views
          likes
          shares
          delivers
          threadReplies
        }
        createdOn
        messageType
        lastUpdated
        status
        statusMessage
        __typename
      }
    }
  }
`;

export const CREATE_COMMUNITY = gql`
  mutation createCommunity($communityData: CommunityData!) {
    createCommunity(communityData: $communityData) {
      ID
      coverPhoto
      title
      description
      createdOn
      creator {
        ID
        username
        fullname
        gender
        profilePic
      }
      statistics {
        members
      }
      peer {
        user {
          ID
          username
          fullname
        }
      }
      lastMessages(includeThreads: true, limit: 30) {
        ID
        communityID
        content
        parentID
        parent {
          ID
          communityID
          messageType
          creator {
            ID
            username
            fullname
            gender
            profilePic
          }
          content
          status
        }
        creator {
          ID
          username
          fullname
          gender
          profilePic
          followStatus
        }
        statistics {
          isLikedSelf
          views
          likes
          shares
          delivers
          threadReplies
        }
        createdOn
        messageType
        lastUpdated
        status
        statusMessage
        __typename
      }
      membership {
        status
        notificationLevel
        online
      }
      reach
      status
    }
  }
`;
export const GET_EMPTY_COMMUNITIES_VALUE = gql`
  {
    isEmptyCommunities @client
  }
`;
export const GET_LOADING_COMMUNITY_VALUE = gql`
  {
    loadingCommunity @client
  }
`;
export const GET_SELECTED_COMMUNITY_VALUE = gql`
  {
    selectedCommunity @client
  }
`;
export const GET_SELECTED_COMMUNITY_NAME_VALUE = gql`
  {
    selectedCommunityName @client
  }
`;
export const UPDATE_SELF_MEMBERSHIP = gql`
  mutation updateSelfMembership(
    $selfMembershipUpdateData: SelfMembershipUpdateData!
  ) {
    updateSelfMembership(selfMembershipUpdateData: $selfMembershipUpdateData)
  }
`;

export const DELETE_COMMUNITY = gql`
  mutation deleteCommunity($ID: ID!, $archiveOnly: Boolean!) {
    deleteCommunity(ID: $ID, archiveOnly: $archiveOnly)
  }
`;

export const UPDATE_COMMUNITY_VALUE = gql`
  mutation updateCommunityValue(
    $community: community!
    $messageData: messageData!
  ) {
    updateCommunityValue(community: $community, messageData: $messageData)
      @client
  }
`;

export const UPDATE_COMMUNITY_VALUES = gql`
  mutation updateCommunityValues(
    $communities: communities!
    $unreadMessages: unreadMessages!
    $isEmptyCommunities: Boolean
  ) {
    updateCommunityValues(
      communities: $communities
      unreadMessages: $unreadMessages
      isEmptyCommunities: $isEmptyCommunities
    ) @client
  }
`;

export const UPDATE_LOADING_COMMUNITY_VALUE = gql`
  mutation updateLoadingCommunity($loading: Boolean!) {
    updateLoadingCommunity(loading: $loading) @client
  }
`;

export const UPDATE_SELECTED_COMMUNITY_VALUE = gql`
  mutation updateSelectedCommunityValue(
    $selectedCommunity: selectedCommunity!
  ) {
    updateSelectedCommunityValue(selectedCommunity: $selectedCommunity) @client
  }
`;
