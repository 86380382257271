import {
  all, takeEvery, takeLatest, put, call
} from 'redux-saga/effects';
import storage from '../services/storage';
import actions from '../shared/actions/storage';

export function* UPLOAD_FILE({ payload }) {
  console.log(payload);
  const task = storage.uploadFile('images', payload.file);

  task.on('state_changed', (snapshot) => {
    const pct = (snapshot.bytesTransferred * 100) / snapshot.totalBytes;
    console.log(`${pct}%`);
  });
  yield task;
}

export default function* rootSaga() {
  yield all([takeEvery(actions.UPLOAD_FILE, UPLOAD_FILE)]);
}
