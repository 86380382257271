import { all } from 'redux-saga/effects';
import auth from './auth';
import channel from './channel';
import subscribe from './subscribe';
import notification from './notification';
import messages from './messages';
import storage from './storage';

export default function* rootSaga() {
  yield all([
    auth(),
    notification(),
    channel(),
    subscribe(),
    storage(),
    messages()
  ]);
}
