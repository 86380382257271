import gql from 'graphql-tag';


export const UPDATE_USER_SETTING = gql`
  mutation updateUserSetting($name: String!, $value: String!) {
    updateUserSetting(name: $name, value: $value)
  }
`;


export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($userID: ID, $userProfileData: UserProfileData!) {
    updateUserProfile(userID: $userID, userProfileData: $userProfileData)
  }
`;



 export const WHO_AM_I = gql`
  query whoAmI {
    whoAmI {
      ID
      fullname
      primaryEmail
      primaryPhone
      profilePic
      investedAmount
      isSubscriber
      isInvestor
      nextPayment
      settings{
        name 
        value
      }
    }
  }
`;