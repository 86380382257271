import gql from 'graphql-tag';

export const GET_FEED_QUERY = gql`
  query getFeed($searchText: String, $offset: Int, $limit: Int) {
    getFeed(searchText: $searchText, offset: $offset, limit: $limit) {
      type
      message {
        ID
        communityID
        messageType
        createdOn
        creator {
          ID
          username
          verified
          fullname
          profilePic
          followedByMe
          followStatus
        }
        status
        content
        statistics {
          isLikedSelf
          views
          likes
          shares
          delivers
          threadReplies
        }
        __typename
      }
      messageCommunity {
        title
      }
    }
  }
`;

export const VOTE_MESSAGE = gql`
  mutation voteMessage($voteData: VoteData!) {
    voteMessage(voteData: $voteData) {
      ID
      communityID
      messageType
      createdOn
      creator {
        ID
        username
        fullname
        profilePic
        followedByMe
        followStatus
      }
      status
      content
      statistics {
        isLikedSelf
        views
        likes
        shares
        delivers
        threadReplies
      }
      __typename
      __typename
    }
  }
`;
export const ADD_FEED_MESSAGE = gql`
  mutation addMessage($addmessageData: AddMessageData!) {
    addMessage(addmessageData: $addmessageData) {
      ID
      communityID
      messageType
      createdOn
      creator {
        ID
        username
        fullname
        profilePic
        followedByMe
        followStatus
      }
      status
      content
      blogPostId
      categoryId
      statistics {
        isLikedSelf
        views
        likes
        shares
        delivers
        threadReplies
      }
      __typename
    }
  }
`;
