import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  container: {
    display: 'flex',
    boxSizing: 'border-box',
    flexBasis: 'auto',
    alignItems: 'stretch',
    flexShrink: 1,
    position: 'relative',
    flexDirection: 'row',
    minHeight: '100vh',
    minWidth: '100%'
  },
  contentContainer: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'flex-start',
    flexDirection: 'column',
    display: 'flex',
    padding: theme.spacing(0)
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
  },
  contentWrapper: {
    minHeight: 'calc(100% - 70px)',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
    },
  },
  childrenContainer: {
    flexGrow: 1
  },
  topToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    minHeight: 70,
    [theme.breakpoints.down('sm')]: {
      minHeight: 60
    }
  },
  bottomToolbar: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      minHeight: 70,
      padding: theme.spacing(0, 1),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end'
    }
  }
}));
