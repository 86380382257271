/* eslint-disable no-case-declarations */
import actions from '../../shared/actions/message';

const initialState = {
  notification: false,
  status: 'connecting',
  isReplyMessage: false,
  isEditMessage: false,
  callback: () => {},
  message: {},
  editMessage: {},
  messages: []
};

export default function messageReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    case actions.ADD_MESSAGE_VALUES:
      return {
        ...state,
        messages: state.messages.concat(action.payload)
      };
    case actions.SET_MESSAGE_VALUE:
      const { messages } = state;
      const mutationIndex = messages.findIndex(
        message => message.ID === action.payload.staticID
      );
      if (mutationIndex === -1) {
        messages.unshift(action.payload.message);
      } else if (mutationIndex !== -1) {
        messages[mutationIndex] = action.payload.message;
      }
      return {
        ...state,
        messages
      };
    case actions.UPDATE_MESSAGE_VALUE:
      return {
        ...state,
        messages: state.messages.map((message) => {
          if (message.ID === action.payload.ID) {
            return action.payload;
          }
          return message;
        })
      };
    default:
      return state;
  }
}
