
export const required = value => (value ? undefined : 'Required');

export const phoneNumber = value => (value && !/^(0|[1-9][0-9]{8,9})$/i.test(value)
  ? 'Invalid phone number'
  : undefined);

export const maxLength = max => value => (value && value.length > max ? `Must be ${max} characters or less` : undefined);

export const minLength = min => value => (value && value.length < min ? `Must be ${min} characters or more` : undefined);
