import { makeStyles } from '@material-ui/styles';
import { stringToColour } from '../../utils';

export default makeStyles(theme => ({
  root: {
    textDecoration: 'none',
  },
  colorDefault: {
    textTransform: 'uppercase',
    backgroundColor: ({ name }) => stringToColour(name, 0.5),
    border: ({ name }) => `2px solid ${stringToColour(name)}`
  },
  medium: {
    width: 56,
    height: 56
  },
  small: {
    width: theme.spacing(8),
    height: theme.spacing(8)
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(7.5),
      height: theme.spacing(7.5),
    }
  }
}));
