import React from 'react';
import PropTypes from 'prop-types';

function withNotification(WrappedComponent) {
  const WrapperComponent = ({ notification, ...props }) => (
    <WrappedComponent notification={notification} {...props} />
  );
  WrapperComponent.contextTypes = {
    notification: PropTypes.instanceOf(Object).isRequired
  };
  WrapperComponent.propTypes = {
    notification: PropTypes.instanceOf(Object)
  };
  WrapperComponent.defaultProps = {
    notification: {}
  };
  return WrapperComponent;
}

export default withNotification;
