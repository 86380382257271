import gql from 'graphql-tag';

export const GET_PHONE_NUMBER = gql`
  {
    phoneNumber @client
  }
`;

export const UPDATE_PHONE_NUMBER = gql`
  mutation updatePhoneNumber($phoneNumber: String!) {
    updatePhoneNumber(phoneNumber: $phoneNumber) @client
  }
`;
