import store from '../../store/configureStore';

const addNotification = payload => store.store.dispatch({
  type: 'notification/SET_SHOW',
  payload
});

const removeNotification = ({ payload }) => store.store.dispatch({
  type: 'notification/SET_DISMISS',
  payload
});

export {
  addNotification,
  removeNotification
};
