import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/react-hooks';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  CircularProgress,
} from '@material-ui/core';
import useStyles from './styles';
import { addNotification } from '../../notification';
import { DELETE_MESSAGE } from '../../../graphql';

const DeletePost = ({ activeModal, selectedValue, onClose }) => {
  console.log("activemodel",activeModal)
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  const { ID } = selectedValue || {};
  const callback = useSelector(state => state.message.callback);
  const [deleteMessage] = useMutation(DELETE_MESSAGE, {
    optimisticResponse: {
      __typename: 'Mutation',
      deleteMessage: {
        ID,
        content: null,
        status: 1,
        __typename: 'Message',
      },
    },
    onError: () => {
      addNotification({
        message: 'Something went wrong please try again later.',
        variant: 'error',
      });
    },
    update: (proxy, { data }) => {
      callback(proxy, data.deleteMessage);
    },
    onCompleted: () => {
      setLoading(false);
    },
  });

  const handleClick = useCallback(() => {
    setLoading(true);
    onClose();
    deleteMessage({
      variables: {
        deletemessageData: {
          blogPostId:selectedValue.ID,
          categoryId:selectedValue.communityID,
          statusMessage: selectedValue.statusMessage,
        },
      },
    });
  }, [deleteMessage, selectedValue]);

  return (
    <Dialog fullWidth maxWidth="sm" open={activeModal}>
      <DialogTitle>Delete</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Are you sure you want to delete this post?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="primary" onClick={handleClick}>
          Delete
        </Button>
      </DialogActions>
      {isLoading && (
      <div className={classes.loaderContainer}>
        <CircularProgress size={24} className={classes.buttonProgress} />
      </div>
      )}
    </Dialog>
  );
};

DeletePost.propTypes = {
  activeModal: PropTypes.bool,
  selectedValue: PropTypes.instanceOf(Object),
  onClose: PropTypes.func,
};

DeletePost.defaultProps = {
  activeModal: false,
  selectedValue: {},
  onClose: () => {},
};

export default React.memo(DeletePost);
