import { useSelector } from 'react-redux';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { withRouter, Redirect } from 'react-router-dom';
import branch from 'branch-sdk';
import PageLoader from '../shared/loader/pageLoader';
import PublicLayout from './Public';
import TwitterLayout from './Twitter';
import StaticLayout from './Static';
import MessageLayout from './Message';
import AuthLayout from './Auth';
import ProfileLayout from './Profile';
import { BRANCH_KEY } from '../config';

const Layouts = {
  public: PublicLayout,
  twitter: TwitterLayout,
  static: StaticLayout,
  message: MessageLayout,
  auth: AuthLayout,
  profile: ProfileLayout,
  main: TwitterLayout,
};
const IndexLayout = ({ children, location: { pathname } }) => {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useSelector(state => ({
    loading: state.auth.authLoading,
    authorized: state.auth.authorized,
  }));
  const options = { no_journeys: false };
  branch.init(BRANCH_KEY, options);
  const getLayout = () => {
    if (pathname === '/') {
      return 'twitter';
    }
    if (/^\/auth(?=\/|$)/i.test(pathname)) {
      if (pathname.match('logout')) {
        return 'static';
      }
      return 'auth';
    }
    if (/^\/profile(?=\/|$)/i.test(pathname)) {
      return 'profile';
    }
    return 'main';
  };
  const Container = Layouts[getLayout()];
  const isUserLoading = user.loading;
  const isUserAuthorized = user.authorized;
  const isLoginLayout = getLayout() === 'auth';
  const BootstrappedLayout = () => {
    if (isUserLoading && !isUserAuthorized && !isLoginLayout) {
      return <PageLoader />;
    }
    if (!isLoginLayout && !isUserAuthorized) {
      return <Redirect to="/auth/login" />;
    }
    if (isLoginLayout && isUserAuthorized) {
      return <Redirect to="/" />;
    }
    
    if (!mobileScreen) {
      if (pathname.match('tejiPicks') || pathname.match('watchlist')) {
        return <Redirect to="/" />;
      }
    }
    return <Container>{children}</Container>;
  };
  return BootstrappedLayout();
};

IndexLayout.propTypes = {};

export default React.memo(withRouter(IndexLayout));
