import updateNetworkStatus from './updateNetworkStatus';
import updateModalName from './updateModalName';
import updatePhoneNumber from './updatePhoneNumber';
import updateModalValue from './updateModalValue';
import updatePreviewValue from './updatePreviewValue';
import updateOrderValue from './updateOrderValue';
import updateSearchValue from './updateSearchValue';
import updateStockValue from './updateStockValue';
import updateMessageValues from './updateMessageValues';
import updateCommunityValue from './updateCommunityValue';
import updateCommunityValues from './updateCommunityValues';
import updateMessageValue from './updateMessageValue';
import updateSelectedMessage from './updateSelectedMessage';
import updateNextPageMessage from './updateNextPageMessage';
import updateLoadingValue from './updateLoadingValue';
import updateLoadingCommunity from './updateLoadingCommunity';
import updateUnreadMessageValue from './updateUnreadMessageValue';
import updateDownMessageButtonValue from './updateDownMessageButtonValue';
import updateSelectedCommunityValue from './updateSelectedCommunityValue';

export default {
  Mutation: {
    updateNetworkStatus,
    updateModalName,
    updateModalValue,
    updatePhoneNumber,
    updatePreviewValue,
    updateOrderValue,
    updateSearchValue,
    updateStockValue,
    updateMessageValues,
    updateMessageValue,
    updateCommunityValue,
    updateCommunityValues,
    updateSelectedMessage,
    updateNextPageMessage,
    updateLoadingValue,
    updateLoadingCommunity,
    updateUnreadMessageValue,
    updateDownMessageButtonValue,
    updateSelectedCommunityValue
  }
};
