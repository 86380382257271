import gql from 'graphql-tag';

export const GLOBAL_SEARCH_QUERY = gql`
  query globalSearch($text: String!, $limit: Int, $offset: Int) {
    globalSearch(text: $text) {
      equities(offset: $offset, limit: $limit) {
        equity {
          symbol
          company
          lastQuote {
            ltp
            change
            changePercent
          }
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
      insights(offset: $offset, limit: $limit) {
        message {
          ID
          communityID
          content
          status
          parentID
          createdOn
          community {
            ID
            title
            status
            coverPhoto
            description
          }
          creator {
            ID
            username
            fullname
            profilePic
          }
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
      users(offset: $offset, limit: $limit) {
        user {
          ID
          username
          fullname
          profilePic
          followedByMe
          followsMe
          followStatus
          backFollowStatus
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
      communities(offset: $offset, limit: $limit) {
        community {
          ID
          title
          status
          coverPhoto
          membership {
            status
          }
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
      messages(offset: $offset, limit: $limit) {
        message {
          ID
          communityID
          content
          status
          parentID
          createdOn
          community {
            ID
            title
            status
            coverPhoto
            description
          }
          creator {
            ID
            username
            fullname
            profilePic
          }
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
    }
  }
`;

export const GLOBAL_EQUITIES_QUERY = gql`
  query getGlobalSearchEquities($text: String!, $limit: Int, $offset: Int) {
    globalSearch(text: $text) {
      equities(offset: $offset, limit: $limit) {
        equity {
          symbol
          company
          lastQuote {
            ltp
            change
            changePercent
          }
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
    }
  }
`;

export const GLOBAL_COMMUNITIES_QUERY = gql`
  query getGlobalSearchCommunities($text: String!, $limit: Int, $offset: Int) {
    globalSearch(text: $text) {
      communities(offset: $offset, limit: $limit) {
        community {
          ID
          title
          status
          coverPhoto
          membership {
            status
          }
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
    }
  }
`;

export const GLOBAL_MESSAGES_QUERY = gql`
  query getGlobalSearchMessages($text: String!, $limit: Int, $offset: Int) {
    globalSearch(text: $text) {
      messages(offset: $offset, limit: $limit) {
        message {
          ID
          communityID
          content
          status
          parentID
          createdOn
          community {
            ID
            title
            status
            coverPhoto
            description
          }
          creator {
            ID
            username
            fullname
            profilePic
          }
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
    }
  }
`;
export const GLOBAL_INSIGHTS_QUERY = gql`
  query getGlobalSearchInsights($text: String!, $limit: Int, $offset: Int) {
    globalSearch(text: $text) {
      insights(offset: $offset, limit: $limit) {
        message {
          ID
          communityID
          content
          status
          parentID
          createdOn
          community {
            ID
            title
            status
            coverPhoto
            description
          }
          creator {
            ID
            username
            fullname
            profilePic
          }
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
    }
  }
`;

export const GLOBAL_USERS_QUERY = gql`
  query getGlobalSearchUsers($text: String!, $limit: Int, $offset: Int) {
    globalSearch(text: $text) {
      users(offset: $offset, limit: $limit) {
        user {
          ID
          username
          fullname
          profilePic
          followedByMe
          followsMe
          followStatus
          backFollowStatus
        }
        search {
          searchedText
          foundText
          score
          total
        }
      }
    }
  }
`;

export const GET_SEARCH_VALUES = gql`
  {
    searchValues @client {
      searchValue
      selectedValue
      totalValue
    }
  }
`;

export const UPDATE_SEARCH_VALUES = gql`
  mutation updateSearchValue($searchValues: searchValues!) {
    updateSearchValue(searchValues: $searchValues) @client
  }
`;
