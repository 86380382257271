/* eslint-disable no-param-reassign */
import classname from 'classnames';
import accepts from 'attr-accept';
import { findAll } from 'highlight-words-core';
import { createElement } from 'react';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

const rule = /([#|＃][^\s]+)/g;
export const highlightString = (
  textToHighlight,
  searchWords = [],
  classes = {},
  symbol = [],
  routerLink = true
) => {
  const chunks = findAll({
    searchWords,
    textToHighlight,
    autoEscape: true,
  });
  return chunks.map((chunk) => {
    const { end, highlight, start } = chunk;
    const text = textToHighlight.substr(start, end - start);
    if (highlight) {
      if (text.search(/mandi/i) > 0) {
        return createElement(
          'span',
          {
            key: Math.random(),
            className: classes.secondary,
          },
          text
        );
      }
      if (symbol.indexOf(text.toLowerCase()) !== -1) {
        if (routerLink) {
          return createElement(
            Link,
            {
              key: Math.random(),
              className: classname(classes.primary, classes.capitalize),
              to: `/stock/${text.split(':').pop().trim().toLowerCase()}`,
            },
            text
          );
        }
        return createElement(
          'span',
          {
            key: Math.random(),
            className: classes.primary,
          },
          text
        );
      }
      return createElement(
        'span',
        {
          key: Math.random(),
          className: classes.primary,
        },
        text
      );
    }
    return text;
  });
};

export const parseToString = (value, classes = {}) => value.split(rule).map((chunk) => {
  if (chunk.match(rule)) {
    if (chunk.search(/mandi/i) > 0) {
      return createElement(
        'span',
        {
          key: chunk,
          className: classes.secondary,
        },
        chunk
      );
    }
    return createElement(
      'span',
      {
        key: chunk,
        className: classes.primary,
      },
      chunk
    );
  }
  return chunk;
});

export const stringToColour = (str, alpha) => {
  if (!str) return '#d295bf';
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    colour += `00${value.toString(16)}`.substr(-2);
  }
  const r = parseInt(colour.slice(1, 3), 16);
  const g = parseInt(colour.slice(3, 5), 16);
  const b = parseInt(colour.slice(5, 7), 16);
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const defaultInitials = (name, maxInitials = 2) => name
  .split(/\s/)
  .map(part => part.substring(0, 1).toUpperCase())
  .filter(v => !!v)
  .slice(0, maxInitials)
  .join('');

export const inputValueFromEvent = (event) => {
  if (!event) return false;
  let currentValue;
  if (event.currentTarget) {
    currentValue = event.currentTarget;
  } else {
    currentValue = event.target;
  }
  const { value } = currentValue;
  return value;
};

function S4() {
  // eslint-disable-next-line no-bitwise
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function generateRandomId() {
  return `${S4() + S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`;
}

const extensions = {
  image: ['image/jpg', 'image/jpeg', 'image/gif', 'image/bmp', 'image/png', 'image/webp'],
  video: ['video/*'],
};

export function fileAccepted(fileValue, withKey) {
  const values = Object.values(extensions);
  const keys = Object.keys(extensions);
  const isFileAccepted = (file, accept) => file.type === 'application/x-moz-file' || accepts(file, accept);
  let withKeyName = null;
  const isAccepted = values.some((value, index) => {
    if (isFileAccepted(fileValue, value.join(','))) {
      if (withKey) {
        withKeyName = keys[index];
      }
      return true;
    }
    return false;
  });
  if (withKeyName) {
    return withKeyName;
  }
  return isAccepted;
}

export const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
};

export const chatTiming = (createdOn) => {
  let Today = new Date();
  let todaysDate = Today.getDate();
  let todaysMonth = Today.getMonth() + 1; // January is 0!
  const todaysYear = Today.getFullYear();

  let yesterday = todaysDate - 1;
  let messageCreatedOn = new Date(Number(createdOn));
  let date = messageCreatedOn.getDate();
  let month = messageCreatedOn.getMonth() + 1; // January is 0!
  const year = messageCreatedOn.getFullYear();

  todaysDate = todaysDate < 10 ? Number(`0${todaysDate}`) : todaysDate;
  date = date < 10 ? Number(`0${date}`) : date;

  month = month < 10 ? Number(`0${month}`) : month;
  todaysMonth = todaysMonth < 10 ? Number(`0${todaysMonth}`) : todaysMonth;

  Today = `${todaysDate}/${todaysMonth}/${todaysYear}`;
  messageCreatedOn = `${date}/${month}/${year}`;
  yesterday = `${yesterday}/${todaysMonth}/${todaysYear}`;
  if (messageCreatedOn === Today) {
    return formatAMPM(new Date(Number(createdOn)));
  }
  if (messageCreatedOn === yesterday) {
    return `Yesterday ${formatAMPM(new Date(Number(createdOn)))}`;
  }
  return messageCreatedOn;
};

export const renameTitle = (values) => {
  const {
    title, creator, reach, peer
  } = values;
  if (isEmpty(values)) return ' ';
  let name = title;
  if (title.match(/self chat/i) && reach === 4) {
    if (creator.fullname && creator.fullname !== null) {
      name = creator.fullname;
    } else if (creator.username && creator.username !== null) {
      name = creator.username;
    } else {
      name = title;
    }
    return name;
  }
  if (title.match(/one to one chat/i) && reach === 5 && peer) {
    const { user } = peer || {};
    const { fullname, username } = user || {};
    if (fullname && fullname !== null) {
      name = fullname;
    } else if (username && username !== null) {
      name = username;
    } else {
      name = title;
    }
  }
  return name;
};

export function parseSymbol(str) {
  let newStr = '';
  for (let index = 0; index < str.length; index++) {
    const char = str[index];
    const prev = str[index - 1];
    const next = str[index + 1];
    if (
      (char === ' '
        && !(prev === ' ' || next === ' ')
        && !(prev === ':' || next === ':'))
      || char !== ' '
    ) {
      newStr += char;
    }
  }
  const stringData = newStr
    .toLowerCase()
    .split(' ')
    .filter(item => item.startsWith('nse:'));
  stringData.forEach((value) => {
    stringData.push(value.split(':').join(': '));
  });
  return stringData;
}
