import { connect } from 'react-redux';
import React, { memo, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import {
  UPDATE_MODAL_NAME,
  GET_AUTO_ADMIN,
  APPLY_AUTO_ADMINS,
  ADD_AUTO_ADMIN,
} from '../../graphql';
import { addNotification } from '../notification';

const Authorize = ({
  authorized,
  profile,
  redirect,
  to,
  isRedirect,
  children,
}) => {
  const [getAutoAdmin, { data }] = useLazyQuery(GET_AUTO_ADMIN);
  const [updateModalName] = useMutation(UPDATE_MODAL_NAME);
  const [applyAutoAdmins] = useMutation(APPLY_AUTO_ADMINS);
  const [addAutoAdmin] = useMutation(ADD_AUTO_ADMIN);

  useEffect(() => {
    if (authorized && profile.type === 'A') {
      getAutoAdmin({
        variables: {
          type: 3
        },
      });
      addAutoAdmin({ variables: { userID: profile.guid } }).then(() => {
        applyAutoAdmins();
      });
    }
  }, [authorized]);
  const handleClick = (event) => {
    event.preventDefault();
    updateModalName({ variables: { activeModalName: 'authLogin' } });
  };
  const AuthorizedChildren = () => {
    if (!authorized && redirect) {
      addNotification({
        message: 'You do not have access, please contact site administrator',
        variant: 'error',
      });
      return <Redirect to="/auth/login" />;
    }
    if (authorized && profile.type !== 'A') {
      if (!isRedirect) {
      } else {
        addNotification({
          message: 'You do not have access, please contact site administrator',
          variant: 'error',
        });
        return <Redirect to={to} />;
      }
    }
    if (authorized && profile.type === 'A') {}
    return React.Children.toArray(children).map((Children) => {
      const onClick = authorized ? Children.props.onClick : handleClick;
      return React.cloneElement(Children, { onClick });
    });
  };
  return AuthorizedChildren();
};

Authorize.propTypes = {
  authorized: PropTypes.bool,
  redirect: PropTypes.bool,
  isRedirect: PropTypes.bool,
  to: PropTypes.string,
};

Authorize.defaultProps = {
  authorized: false,
  redirect: false,
  isRedirect: true,
  to: '/',
};

const mapStateToProps = state => ({
  authorized: state.auth.authorized,
  profile: state.auth.profile,
});

export default connect(mapStateToProps)(memo(Authorize));
