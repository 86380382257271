import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Typography, CircularProgress } from '@material-ui/core';
import { GoogleIcon } from '../../../assets/icons';
import useStyles from './styles';

const GoogleProvider = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);
  const handleClick = () => {
    dispatch({
      type: 'user/GOOGLE_AUTH_LOGIN'
    });
  };
  return (
    <div className={classes.authButtonContainer}>
      <Typography className={classes.labelText}>Or</Typography>
      <div className={classes.loaderContainer}>
        <Button
          variant="contained"
          onClick={handleClick}
          disabled={loading}
          classes={{
            startIcon: classes.startIcon
          }}
          startIcon={<GoogleIcon />}
          className={classes.authGoogleButton}
        >
          Continue with Google
        </Button>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  );
};

GoogleProvider.propTypes = {};

export default memo(GoogleProvider);
