import {
  all, takeEvery, put, call
} from 'redux-saga/effects';
import { subscribeToMailchimp } from '../services/subscribe';
import actions from '../shared/actions/subscribe';

export function* ADD_TO_SUBSCRIBE({ payload }) {
  console.log(payload, 'payload');
  const response = yield call(subscribeToMailchimp, payload);
  console.log(response, 'response');
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.ADD_TO_SUBSCRIBE, ADD_TO_SUBSCRIBE),
  ]);
}
