import React, { memo, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  Divider,
  CircularProgress
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '../../avatar';
import { SEARCH_COMMUNITIES, UPDATE_SELF_MEMBERSHIP } from '../../../graphql';
import useStyles from './styles';

const Loader = ({ number }) => {
  const classes = useStyles();
  return Array.from({ length: number }).map((v, index) => (
    <React.Fragment key={index}>
      <ListItem>
        <ListItemAvatar className={classes.avatar}>
          <Skeleton variant="circle" width={64} height={64} />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Skeleton height={10} width="50%" style={{ marginBottom: 6 }} />
          }
          secondary={<Skeleton height={10} width="20%" />}
        />
        <ListItemSecondaryAction>
          <Skeleton
            variant="rect"
            height={30}
            width={65}
            style={{ borderRadius: 8 }}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </React.Fragment>
  ));
};

Loader.propTypes = {
  number: PropTypes.number
};

Loader.defaultProps = {
  number: 6
};

const Community = ({
  ID, title, description, coverPhoto
}) => {
  const classes = useStyles();
  const [{ status, loading }, dispatch] = useReducer(
    (reducerState, newState) => ({
      ...reducerState,
      ...newState
    }),
    {
      status: false,
      loading: false
    }
  );
  const [updateSelfMembership] = useMutation(UPDATE_SELF_MEMBERSHIP, {
    optimisticResponse: {
      __typename: 'Mutation',
      updateSelfMembership: !status
    },
    update: () => {
      dispatch({
        status: !status,
        loading: false
      });
    }
  });

  const variant = (!status && 'outlined') || 'contained';
  const handleClick = useCallback(() => {
    dispatch({ loading: true });
    const newStatus = status ? -1 : 20;
    updateSelfMembership({
      variables: {
        selfMembershipUpdateData: {
          communityID: ID,
          newStatus,
          notificationLevel: 0
        }
      }
    });
  }, [updateSelfMembership, status, ID]);
  return (
    <React.Fragment>
      <ListItem>
        <ListItemAvatar className={classes.avatar}>
          <Avatar name={title} src={coverPhoto} small />
        </ListItemAvatar>
        <ListItemText
          classes={{
            primary: classes.primary
          }}
          primary={title}
          secondary={description}
        />
        <ListItemSecondaryAction>
          <div className={classes.buttonContainer}>
            <Button
              size="small"
              variant={variant}
              disabled={loading}
              color="primary"
              onClick={handleClick}
            >
              {!status ? 'Join' : 'joined'}
            </Button>
            {loading && (
              <CircularProgress size={15} className={classes.buttonProgress} />
            )}
          </div>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="fullWidth" component="li" />
    </React.Fragment>
  );
};

Community.propTypes = {
  ID: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  coverPhoto: PropTypes.string
};

Community.defaultProps = {
  ID: '',
  title: '',
  description: '',
  coverPhoto: ''
};

const Communities = () => {
  const classes = useStyles();
  const { loading, error, data } = useQuery(SEARCH_COMMUNITIES, {
    variables: {
      searchText: '',
      joined: false
    }
  });
  if (error) return `Error! ${error.message}`;
  return (
    <div className={classes.communityContainer}>
      <Grid container justify="center">
        <Grid item xs={12} md={12}>
          <Typography
            variant="h4"
            component="h4"
            gutterBottom
            align="center"
            className={classes.titleLabel}
          >
            Some handpicked groups for you
          </Typography>
          <Typography
            variant="subtitle2"
            component="h4"
            gutterBottom
            align="center"
            className={classes.subtitleLabel}
          >
            Choose 1 or more
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.scrollContainer}>
        <List>
          <Divider variant="fullWidth" component="li" />
          {loading && <Loader />}
          {!loading
            && data.searchCommunities.map(community => (
              <Community key={community.ID} {...community} />
            ))}
        </List>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          type="submit"
          variant="outlined"
          color="primary"
          className={classes.button}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

Communities.propTypes = {};

export default memo(Communities);
