/* eslint-disable no-restricted-syntax */
import isEmpty from 'lodash/isEmpty';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { ApolloLink, Observable, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import configureStore from './configureStore';
import { refreshToken } from '../shared/actions/auth';
import { GRAPHQL_URL, WSS_URL } from '../config';
import resolvers from '../graphql/resolvers';
import defaults from '../graphql/defaults';

const memoryCache = new InMemoryCache();

const stateLink = withClientState({
  cache: memoryCache,
  defaults,
  resolvers
});

const request = async (operation) => {
  let token = localStorage.getItem('token');
  if (window.AndroidInterface) {
    token = window.AndroidInterface.getUserToken();
    if (isEmpty(token)) {
      window.AndroidInterface.showToast('Please re-login to proceed');
    }
  }
  configureStore.store.dispatch(refreshToken());
  return operation.setContext({
    headers: {
      'api-version': 2,
      authorization: `Bearer ${token}`
    }
  });
};

const requestLink = new ApolloLink(
  (operation, forward) => new Observable((observer) => {
    let handle;
    Promise.resolve(operation)
      .then(oper => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        });
      })
      .catch(observer.error.bind(observer));
    return () => {
      if (handle) handle.unsubscribe();
    };
  })
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log(graphQLErrors, 'graphQLErrors');
  }
  if (networkError) {
    console.log(networkError, 'networkError');
  }
});

const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  'api-version': 2,
  credentials: 'same-origin'
});

const wsLink = new WebSocketLink({
  uri: WSS_URL,
  options: {
    reconnect: true,
    timeout: 30000,
    lazy: true,
    connectionParams: () => ({
      Authorization: `Bearer ${localStorage.getItem('token')}`
    })
  }
});

wsLink.subscriptionClient.onConnecting(() => {
  configureStore.store.dispatch({
    type: 'message/INIT_SOCKET_CONNECTION',
    payload: {
      status: 'connecting',
      notification: true
    }
  });
});

wsLink.subscriptionClient.onConnected(() => {
  configureStore.store.dispatch({
    type: 'message/INIT_SOCKET_CONNECTION',
    payload: {
      status: 'connected',
      notification: false
    }
  });
});

wsLink.subscriptionClient.onReconnecting(() => {
  configureStore.store.dispatch({
    type: 'message/INIT_SOCKET_CONNECTION',
    payload: {
      status: 'reconnecting',
      notification: true
    }
  });
});

wsLink.subscriptionClient.onReconnected(() => {
  configureStore.store.dispatch({
    type: 'message/INIT_SOCKET_CONNECTION',
    payload: {
      status: 'reconnected',
      notification: false
    }
  });
});

wsLink.subscriptionClient.onDisconnected(() => {
  configureStore.store.dispatch({
    type: 'message/INIT_SOCKET_CONNECTION',
    payload: {
      status: 'disconnected',
      notification: false
    }
  });
});

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  wsLink,
  httpLink
);

const links = [errorLink, requestLink, stateLink, terminatingLink];

const link = ApolloLink.from(links);

export default new ApolloClient({
  link,
  cache: memoryCache
});
