const actions = {
  SET_STATE: 'user/SET_STATE',
  PHONE_SEND_LOGIN: 'user/PHONE_SEND_LOGIN',
  GOOGLE_AUTH_LOGIN: 'user/GOOGLE_AUTH_LOGIN',
  ANONYMOUSLY_LOGIN: 'user/ANONYMOUSLY_LOGIN',
  SEND_OTP_CODE: 'user/SEND_OTP_CODE',
  VERIFY: 'user/VERIFY',
  REFRESH_TOKEN: 'user/REFRESH_TOKEN',
  UPDATE_PROFILE: 'user/UPDATE_PROFILE',
  UPDATE_USER_AVATAR: 'user/UPDATE_USER_AVATAR',
  UPDATE_USER_PROFILE: 'user/UPDATE_USER_PROFILE',
  UPDATE_USER_SETTING: 'user/UPDATE_USER_SETTING',
  UPDATE_USER_GROUP_ADMIN: 'user/UPDATE_USER_GROUP_ADMIN',
  CLEAR_RECAPTCHA: 'user/CLEAR_RECAPTCHA',
  GET_USER_PROFILE: 'user/GET_USER_PROFILE',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT_ACTIVE_ACCOUNT: 'user/LOGOUT_ACTIVE_ACCOUNT',
};

export const refreshToken = () => dispatch => new Promise((resolve, reject) => {
  dispatch({
    type: 'user/REFRESH_TOKEN',
    resolve,
    reject,
  });
});

export default actions;
