import gql from "graphql-tag";

export const TOGGLE_LIKE_MESSAGE = gql`
  mutation toggleLikeMessage($ID: ID!) {
    toggleLikeMessage(ID: $ID) {
      ID
      statistics {
        isLikedSelf
        likes
      }
      __typename
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation deleteMessage($deletemessageData: DeleteMessageData!) {
    deleteMessage(deletemessageData: $deletemessageData) {
      ID
      status
      content
      __typename
    }
  }
`;

export const REPORT_MESSAGE = gql`
  mutation reportMessage($reportmessageData: ReportMessageData!) {
    reportMessage(reportmessageData: $reportmessageData) {
      ID
      status
      __typename
    }
  }
`;

export const SHARE_MESSAGE = gql`
  mutation setMessageShares($messageShareData: [MessageShareData!]) {
    setMessageShares(messageShareData: $messageShareData)
  }
`;
export const GET_USER_MESSAGES = gql`
  query getMessages($getMessageData: GetMessageData!) {
    getMessages(messages: [$getMessageData]) {
      ID
      communityID
      content
      creator {
        ID
        username
        fullname
        gender
        verified
        profilePic
        followStatus
      }
      createdOn
      messageType
      lastUpdated
      status
      statusMessage
      __typename
    }
  }
`;

export const GET_FEEDV3 = gql `
query getFeedV3(
  $searchText: String
  $blogPostId: ID
  $categoryId: ID
  $offset: Int
  $status:Int
  $limit: Int 
) {
  getFeedV3(
    searchText: $searchText
    blogPostId: $blogPostId
    categoryId: $categoryId
    offset: $offset
    status:$status
    limit: $limit
  ) {
    blogPost {
      ID
      content
      categoryId
      messageId
      status
      creator{
        ID
        username
        fullname
        gender
        verified
        profilePic
        followStatus 
      }
      createdOn
        lastUpdated
    }
  }
}
`

export const GET_BLOGPOST_BY_ID = gql`
query getBlogPostById($blogPostId: ID  $categoryId: ID $showLockedContent : Boolean) {
  getBlogPostById(blogPostId:$blogPostId, categoryId:$categoryId, showLockedContent : $showLockedContent){
    ID
    content
    categoryId
    messageId
    status
    creator {
      ID
      username
      fullname
      gender
      verified
      profilePic
      followStatus
    }
    createdOn
    lastUpdated
}
}
`

export const GET_MESSAGES = gql`
  query getLatestChatMessages(
    $parentCommunity: ID!
    $lastMessageID: ID
    $parentMessage: ID
    $limit: Int
    $includeLatest: Boolean!
    $includeThreads: Boolean!
    $messageTypes: [ID!]
  ) {
    getLatestChatMessages(
      parentCommunity: $parentCommunity
      parentMessage: $parentMessage
      lastMessageID: $lastMessageID
      limit: $limit
      includeLatest: $includeLatest
      includeThreads: $includeThreads
      messageTypes: $messageTypes
    ) {
      ID
      communityID
      content
      creator {
        ID
        username
        fullname
        gender
        verified
        profilePic
        followStatus
      }
      statistics {
        isLikedSelf
        views
        likes
        shares
        delivers
        threadReplies
      }
      parentID
      parent {
        ID
        communityID
        messageType
        creator {
          ID
          username
          fullname
          gender
          verified
          profilePic
        }
        content
        status
        __typename
      }
      createdOn
      messageType
      lastUpdated
      status
      statusMessage
      __typename
    }
  }
`;

export const GET_MESSAGE_IN_RANGE = gql`
  query getChatMessagesInRange(
    $parentCommunity: ID!
    $upperMessageID: ID!
    $lowerMessageID: ID!
    $parentMessage: ID
    $limit: Int
    $includeLatest: Boolean!
    $includeThreads: Boolean!
    $messageTypes: [ID!]
  ) {
    getChatMessagesInRange(
      parentCommunity: $parentCommunity
      parentMessage: $parentMessage
      upperMessageID: $upperMessageID
      lowerMessageID: $lowerMessageID
      limit: $limit
      includeLatest: $includeLatest
      includeThreads: $includeThreads
      messageTypes: $messageTypes
    ) {
      ID
      communityID
      content
      creator {
        ID
        username
        fullname
        gender
        profilePic
        followStatus
      }
      statistics {
        isLikedSelf
        views
        likes
        shares
        delivers
        threadReplies
      }
      parentID
      parent {
        ID
        communityID
        messageType
        creator {
          ID
          username
          fullname
          gender
          profilePic
        }
        content
        status
        __typename
      }
      createdOn
      messageType
      lastUpdated
      status
      statusMessage
      __typename
    }
  }
`;

export const GET_LATEST_MESSAGE_ID = gql`
  query getLatestAvailableMessageID(
    $parentCommunity: ID!
    $parentMessage: ID
    $includeThreads: Boolean!
  ) {
    getLatestAvailableMessageID(
      parentCommunity: $parentCommunity
      parentMessage: $parentMessage
      includeThreads: $includeThreads
    )
  }
`;
export const GET_SELECTED_COMMUNITY_ID = gql`
  {
    selected @client
  }
`;

export const GET_NEXT_PAGE_MESSAGE = gql`
  {
    hasNextPageMessage @client
  }
`;
export const GET_TOTAL_MESSAGE_VALUE = gql`
  {
    countMessages @client
  }
`;
export const GET_UNREAD_MESSAGE_VALUE = gql`
  {
    unreadMessage @client
    downMessageButton @client
  }
`;
export const GET_UNREAD_MESSAGES_VALUE = gql`
  {
    unreadMessages @client {
      ID
      count
    }
  }
`;

export const GET_DOWN_MESSAGE_BUTTON_VALUE = gql`
  {
    downMessageButton @client
  }
`;

export const GET_MESSAGE_VALUES = gql`
  {
    hasNextMessage @client
    loading @client
    countMessages @client
    messages @client {
      ID
      communityID
      content
      creator {
        ID
        username
        fullname
        gender
        profilePic
        followStatus
      }
      parentID
      parent {
        ID
        communityID
        messageType
        creator {
          ID
          username
          fullname
          gender
          profilePic
        }
        content
        status
        __typename
      }
      createdOn
      messageType
      lastUpdated
      status
      statusMessage
      statistics {
        isLikedSelf
        views
        likes
        shares
        delivers
        threadReplies
      }
      __typename
    }
  }
`;

export const GET_MESSAGE_VALUE = gql`
  {
    isReplyMessage @client
    message @client {
      ID
      communityID
      content
      creator {
        ID
        username
        fullname
        gender
        profilePic
        followStatus
      }
      parentID
      parent {
        ID
        communityID
        messageType
        creator {
          ID
          username
          fullname
          gender
          profilePic
        }
        content
        status
      }
      createdOn
      messageType
      lastUpdated
      status
      statusMessage
      statistics {
        isLikedSelf
        views
        likes
        shares
        delivers
        threadReplies
      }
      __typename
    }
  }
`;

export const ADD_MESSAGE = gql`
  mutation addMessage($addmessageData: AddMessageData!) {
    addMessage(addmessageData: $addmessageData) {
      ID
      communityID
      content
      creator {
        ID
        username
        fullname
        gender
        profilePic
        followStatus
      }
      statistics {
        isLikedSelf
        views
        likes
        shares
        delivers
        threadReplies
      }
      parentID
      parent {
        ID
        communityID
        messageType
        creator {
          ID
          username
          fullname
          gender
          profilePic
        }
        content
        status
      }
      createdOn
      messageType
      lastUpdated
      status
      statusMessage
      __typename
    }
  }
`;
export const EDIT_MESSAGE = gql`
  mutation editMessage($editmessageData: EditMessageData!) {
    editMessage(editmessageData: $editmessageData) {
      ID
      communityID
      content
      creator {
        ID
        username
        fullname
        gender
        verified
        profilePic
        followStatus
      }
      createdOn
      messageType
      lastUpdated
      status
      statusMessage
      __typename
    }
  }
`;

export const UPDATE_SELECTED_MESSAGE = gql`
  mutation updateSelectedMessage($selected: selected!) {
    updateSelectedMessage(selected: $selected) @client
  }
`;

export const UPDATE_MESSAGE_VALUES = gql`
  mutation updateMessageValues($messages: messages!, $hasNextMessage: Boolean) {
    updateMessageValues(messages: $messages, hasNextMessage: $hasNextMessage)
      @client
  }
`;

export const UPDATE_MESSAGE_VALUE = gql`
  mutation updateMessageValue($message: message!, $isReplyMessage: Boolean) {
    updateMessageValue(message: $message, isReplyMessage: $isReplyMessage)
      @client
  }
`;

export const UPDATE_NEXT_PAGE_MESSAGE = gql`
  mutation updateNextPageMessage($hasNextPageMessage: hasNextPageMessage!) {
    updateNextPageMessage(hasNextPageMessage: $hasNextPageMessage) @client
  }
`;

export const UPDATE_LOADING_VALUE = gql`
  mutation updateLoadingValue(
    $loading: loading!
    $selectedCommunity: selectedCommunity!
  ) {
    updateLoadingValue(
      loading: $loading
      selectedCommunity: $selectedCommunity
    ) @client
  }
`;

export const UPDATE_UNREAD_MESSAGE_VALUE = gql`
  mutation updateUnreadMessageValue($unreadMessage: Number!) {
    updateUnreadMessageValue(unreadMessage: $unreadMessage) @client
  }
`;

export const UPDATE_UNREAD_MESSAGES_VALUE = gql`
  mutation updateUnreadMessagesValue($unreadMessages: unreadMessages!) {
    updateUnreadMessagesValue(unreadMessage: $unreadMessages) @client
  }
`;

export const UPDATE_DOWN_MESSAGE_BUTTON_VALUE = gql`
  mutation updateDownMessageButtonValue($downMessageButton: Boolean!) {
    updateDownMessageButtonValue(downMessageButton: $downMessageButton) @client
  }
`;
