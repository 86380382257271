import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    backgroundColor: '#f9f8f8',
  },
  boxContainer: {
    maxWidth: 420,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      justifyContent: 'start',
    },
  },
  infoContainer: {
    textAlign: 'center',
    padding: '20px 0',
    [theme.breakpoints.down('xs')]: {
      padding: '10px 0',
    },
  },
  infoTextTitle: {
    fontSize: 22,
    marginBottom: 14,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  infoButton: {
    borderRadius: 5,
    padding: 0,
  },
  infoMedia: {
    maxWidth: '100%',
    maxHeight: '100%',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
    },
  }
}));
