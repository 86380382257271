import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  typography: {
    fontFamily: 'Open Sans,sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    button: {
      fontFamily: 'Open Sans,sans-serif',
    },
    body1: {
      fontSize: 18
    },
    h6: {
      fontSize: 18
    }
  },
  palette: {
    background: {
      default: '#fff',
      dark: '#60c4c4'
    },
    primary: {
      main: '#5551FF',
    },
    secondary: {
      main: '#9E0B0F',
      light: '#ff4081'
    },
    action: {
      main: '#0082FF'
    },
    text: {
      primary: '#252525'
    }
  },
});
