import axios from 'axios';
import { API_URL } from './index';
import configureStore from '../store/configureStore';
import { refreshToken } from '../shared/actions/auth';

axios.defaults.baseURL = API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post.Accept = 'application/json';
axios.defaults.timeout = 60 * 4 * 1000;
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

export const onAccessTokenFetched = (accessToken) => {
  subscribers = subscribers.filter(callback => callback(accessToken));
};

export const addSubscriber = (callback) => {
  subscribers.push(callback);
};

export const getToken = () => configureStore.store.dispatch(refreshToken())
  .then(accessToken => Promise.resolve(accessToken));

function axiosApi(version = 2) {
  const apiCall = axios.create({
    baseURL: `${API_URL}?r=v${version}/`,
  });

  apiCall.interceptors.request.use((config) => {
    const AUTH_TOKEN = localStorage.getItem('token');
    config.headers.Authorization = AUTH_TOKEN ? `Bearer ${AUTH_TOKEN}` : '';
    return config;
  });

  apiCall.interceptors.response.use(response => response, (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 || (error.response.status === 500 && error.response.data.data.message.includes('This token is expired since'))) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        getToken().then((accessToken) => {
          isAlreadyFetchingAccessToken = false;
          onAccessTokenFetched(accessToken);
        }).catch(() => {
          console.log('error');
        });
      }
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken) => {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
    return Promise.reject(error);
  });
  return apiCall;
}

export default axiosApi;
