import actions from '../../shared/actions/auth';

const initialState = {
  authorized: false,
  loading: false,
  authLoading: false,
  profileLoading: false,
  profile: {},
  messageSent: false,
  error: false,
  success: false,
  user: {}
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
