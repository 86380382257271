export default (_, { community, messageData }, { cache }) => {
  const {
    messages,
    hasNextMessage,
    hasNextPageMessage,
    selectedCommunity,
    selectedGroupName,
    unreadMessages
  } = messageData;
  cache.writeData({
    data: {
      getCommunity: community,
      messages,
      countMessages: 0,
      hasNextMessage,
      hasNextPageMessage,
      selectedCommunity,
      selectedCommunityName: selectedGroupName,
      unreadMessages
    }
  });
  return null;
};
